



































































import { Component, Mixins } from 'vue-property-decorator';
import ElePropertiesMixins from './mixins/ElePropertiesMixins';

/**
 * input的配置项
 */
@Component({
  name: 'ImageConfig',
  components: {}
})
export default class extends Mixins(ElePropertiesMixins) {
  acceptTypeList: string[] = [
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.zip',
    '.rar',
    '.7z',
    '.jpg',
    '.jpeg',
    '.png',
    '.mp3',
    '.mp4'
  ];
}
