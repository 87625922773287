import { render, staticRenderFns } from "./image.vue?vue&type=template&id=0a22dfa8&scoped=true&"
import script from "./image.vue?vue&type=script&lang=ts&"
export * from "./image.vue?vue&type=script&lang=ts&"
import style0 from "./image.vue?vue&type=style&index=0&id=0a22dfa8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a22dfa8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\123\\river-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a22dfa8')) {
      api.createRecord('0a22dfa8', component.options)
    } else {
      api.reload('0a22dfa8', component.options)
    }
    module.hot.accept("./image.vue?vue&type=template&id=0a22dfa8&scoped=true&", function () {
      api.rerender('0a22dfa8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/form-design/config/elements/image.vue"
export default component.exports