var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "image",
          expression: "widget.compType === 'image'",
        },
      ],
    },
    [
      [
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_name") } },
          [
            _c("lang-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compName,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compName", $$v)
                },
                expression: "widget.compName",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_code") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compCode,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compCode", $$v)
                },
                expression: "widget.compCode",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_width") } },
          [
            _c("el-input-number", {
              attrs: { min: 1, max: 100 },
              model: {
                value: _vm.widget.properties.width,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "width", $$v)
                },
                expression: "widget.properties.width",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_height") } },
          [
            _c("el-input-number", {
              attrs: { min: 1, max: 100 },
              model: {
                value: _vm.widget.properties.width,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "width", $$v)
                },
                expression: "widget.properties.width",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_display_label") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.showLabel,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "showLabel", $$v)
                },
                expression: "widget.properties.showLabel",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_label_width") } },
          [
            _c("el-input-number", {
              attrs: { min: 0 },
              model: {
                value: _vm.widget.properties.labelWidth,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "labelWidth", $$v)
                },
                expression: "widget.properties.labelWidth",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_label_position") } },
          [
            _c(
              "el-select",
              {
                model: {
                  value: _vm.widget.properties.labelPosition,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "labelPosition", $$v)
                  },
                  expression: "widget.properties.labelPosition",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_align_left"), value: "left" },
                }),
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_center"), value: "center" },
                }),
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_align_right"), value: "right" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_label_border") } },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.widget.properties.cellBorder,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "cellBorder", $$v)
                  },
                  expression: "widget.properties.cellBorder",
                },
              },
              [
                _c("el-radio", { attrs: { label: true } }, [
                  _vm._v(_vm._s(_vm.$t("lang_display"))),
                ]),
                _c("el-radio", { attrs: { label: false } }, [
                  _vm._v(_vm._s(_vm.$t("lang_hide"))),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_request_address") } },
          [
            _c("el-input", {
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.properties.action,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "action", $$v)
                },
                expression: "widget.properties.action",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_allowed_file_types") } },
          [
            _c(
              "el-select",
              {
                attrs: {
                  multiple: "",
                  clearable: "",
                  filterable: "",
                  placeholder: _vm.$t("lang_please_select"),
                },
                model: {
                  value: _vm.widget.properties.accepts,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "accepts", $$v)
                  },
                  expression: "widget.properties.accepts",
                },
              },
              _vm._l(_vm.acceptTypeList, function (item) {
                return _c("el-option", {
                  key: item,
                  attrs: { label: item, value: item },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_allow_multiple_selections") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.multiple,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "multiple", $$v)
                },
                expression: "widget.properties.multiple",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.widget.properties.multiple,
                expression: "widget.properties.multiple",
              },
            ],
            attrs: { label: "限制个数" },
          },
          [
            _c("el-input-number", {
              attrs: { min: 1 },
              model: {
                value: _vm.widget.properties.limit,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "limit", $$v)
                },
                expression: "widget.properties.limit",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.widget.properties.maxSize,
                expression: "widget.properties.maxSize",
              },
            ],
            attrs: { label: _vm.$t("lang_limit_size") },
          },
          [
            _c("el-input-number", {
              attrs: { min: 1 },
              model: {
                value: _vm.widget.properties.maxSize,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "maxSize", $$v)
                },
                expression: "widget.properties.maxSize",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_can_I_download_it") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.downloadable,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "downloadable", $$v)
                },
                expression: "widget.properties.downloadable",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_can_I_preview") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.previewable,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "previewable", $$v)
                },
                expression: "widget.properties.previewable",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_operation_status") } },
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: _vm.$t("lang_please_select"),
                  clearable: "",
                },
                model: {
                  value: _vm.widget.properties.operation,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "operation", $$v)
                  },
                  expression: "widget.properties.operation",
                },
              },
              _vm._l(_vm.statusList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.label, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }